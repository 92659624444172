import { useRecoilState, useSetRecoilState } from 'recoil';
import { userAtom } from '../atoms/auth';
import { apiLoadingFamily, apiErrorsFamily } from '../atoms/api';
import useToastActions from './toasts';
import useApi from '../../hooks/useApi';
import identifyWithUserResponse from '../../helpers/identifyWithUserResponse';
import promiseTimeout from '../../helpers/promiseTimeout';

export default function useAuthActions() {
  // Loading states
  const setUserLoadingState = useSetRecoilState(apiLoadingFamily('user'));
  const setForgotPasswordLoadingState = useSetRecoilState(apiLoadingFamily('forgot-password'));
  const setTrackTraceDemoLoadingState = useSetRecoilState(apiLoadingFamily('track-trace-demo'));
  const setLoginLoadingState = useSetRecoilState(apiLoadingFamily('login'));
  const setResetPasswordLoadingState = useSetRecoilState(apiLoadingFamily('reset-password'));

  // Errors
  const setForgotPasswordErrorsState = useSetRecoilState(apiErrorsFamily('forgot-password'));
  const setTrackTraceDemoErrorsState = useSetRecoilState(apiErrorsFamily('track-trace-demo'));
  const setLoginErrorsState = useSetRecoilState(apiErrorsFamily('login'));
  const setResetPasswordErrorsState = useSetRecoilState(apiErrorsFamily('reset-password'));

  const [user, setUser] = useRecoilState(userAtom);
  const toastActions = useToastActions();

  const { handle: loginApiHandle } = useApi({
    onLoadingStateChange: (loadingState) => setLoginLoadingState(loadingState),
    onValidationErrors: (errors) => setLoginErrorsState(errors),
  });

  const { handle: forgotPasswordApiHandle } = useApi({
    onLoadingStateChange: (loadingState) => setForgotPasswordLoadingState(loadingState),
    onError: (err) => toastActions.addErrorFromObject(err),
    onValidationErrors: (errors) => setForgotPasswordErrorsState(errors),
  });

  const { handle: fetchUserApiHandle } = useApi({
    onLoadingStateChange: (loadingState) => setUserLoadingState(loadingState),
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: resetPasswordApiHandle } = useApi({
    onLoadingStateChange: (loadingState) => setResetPasswordLoadingState(loadingState),
    onError: (err) => toastActions.addErrorFromObject(err),
    onValidationErrors: (errors) => setResetPasswordErrorsState(errors),
  });

  const { handle: trackTraceDemoHandle } = useApi({
    onLoadingStateChange: (loadingState) => setTrackTraceDemoLoadingState(loadingState),
    onError: (err) => toastActions.addErrorFromObject(err),
    onValidationErrors: (errors) => setTrackTraceDemoErrorsState(errors),
  });

  const { handle: logoutApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: impersonateApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  async function fetchUser() {
    if (!user) {
      return;
    }

    const results = await fetchUserApiHandle('get', 'me');

    if (results) {
      setUser(results);
      identifyWithUserResponse(results);
    }
  }

  async function login(loginData) {
    const results = await loginApiHandle('post', 'login', loginData);

    if (window.Echo) {
      window.Echo.leaveAllChannels();
    }

    if (results) {
      setUser(results);
    }

    return results;
  }

  async function resetPassword(resetData) {
    const results = await resetPasswordApiHandle('post', 'reset-password', resetData);

    return results;
  }

  async function logout() {
    setUser(null);
    await logoutApiHandle('post', 'logout');

    if (window.Echo) {
      window.Echo.leaveAllChannels();
    }
  }

  async function requestPasswordReset(forgotPasswordData) {
    await forgotPasswordApiHandle('post', 'forgot-password', forgotPasswordData);
    return true;
  }

  async function trackTraceDemo(formData) {
    await trackTraceDemoHandle('post', 'track-trace-demo', formData);
    return true;
  }

  async function impersonate(token) {
    if (user) {
      await logout();
    }

    const result = await impersonateApiHandle('post', 'impersonate', {
      token,
    });

    if (window.Echo) {
      window.Echo.leaveAllChannels();
    }

    if (result) {
      setUser(result);
    }

    await promiseTimeout(300);

    return result;
  }

  return {
    fetchUser,
    login,
    logout,
    requestPasswordReset,
    resetPassword,
    impersonate,
    trackTraceDemo,
  };
}
