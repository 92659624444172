import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import { activeAddonsSelector } from '../../store/selectors/auth';
import SvgIcon from '../SvgIcon';
import Button from '../Button';
import { translationsAtom } from '../../store/atoms/i18n';

export default function Addon({
  title, description, className, price, icon, identifier, priceId, onOrderAddonClick,
}) {
  const t = useRecoilValue(translationsAtom);
  const activeAddons = useRecoilValue(activeAddonsSelector);

  const hasAddon = useCallback(() => {
    return activeAddons
      && activeAddons.find((activeAddon) => activeAddon.plan_identifier === identifier);
  }, [activeAddons, identifier]);

  const addonHasPrice = !!price && parseInt(price, 10) > 0;

  return (
    <div className={`rounded-xl px-8 py-4 flex flex-col justify-between text-primary-darker ${className}`}>
      <div className="mb-12 h-full">
        <div className="flex justify-between h-full gap-x-3">
          <div>
            <h3 className="text-xl font-bold mb-2">{title}</h3>

            <p>{description}</p>
          </div>

          <SvgIcon identifier={icon} className="w-14 h-14 flex-shrink-0 text-primary-darker" />
        </div>
      </div>

      {addonHasPrice && (
        <div className="flex justify-between">
          <div>
            <div className="text-2xl font-bold">{`+ € ${price},-`}</div>

            <div>{t.subscription_modal.per_vehicle_per_month}</div>
          </div>

          <Button
            className="h-fit"
            styleType="positive"
            onClick={onOrderAddonClick}
            type="button"
            disabled={!!hasAddon()}
            buttonId={priceId}
          >
            {hasAddon() ? t.subscription_modal.active : t.subscription_modal.activate}
          </Button>
        </div>
      )}
    </div>
  );
}

Addon.defaultProps = {
  className: 'bg-yellow-300/20',
  priceId: null,
  price: 0,
};

Addon.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  priceId: PropTypes.string,
  onOrderAddonClick: PropTypes.func.isRequired,
};
