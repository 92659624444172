import { useRecoilValue } from 'recoil';
import { translationsAtom } from '../store/atoms/i18n';

export default function useRouteErrors() {
  const t = useRecoilValue(translationsAtom);

  function format(error) {
    const { message } = error;

    if (message === 'server error') {
      return t.schedule_detail.error_messages.server_error;
    }

    if (message === 'invalid time window') {
      return t.schedule_detail.error_messages.invalid_time_window;
    }

    if (message === 'invalid breaks') {
      return t.schedule_detail.error_messages.invalid_breaks;
    }

    if (message === 'capacity formatted incorrectly') {
      return t.schedule_detail.error_messages.invalid_capacity;
    }

    if (message === 'no route found') {
      return t.schedule_detail.error_messages.no_route;
    }

    if (message === 'too many locations') {
      return t.schedule_detail.error_messages.location_limit;
    }

    return t.schedule_detail.error_messages.server_error;
  }

  return {
    format,
  };
}
