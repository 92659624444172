import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useRecoilValue } from 'recoil';
import { translationsAtom } from '../../store/atoms/i18n';
import { jobModalOpenAtom, modalJobAtom } from '../../store/atoms/jobModal';
import SvgIcon from '../../components/SvgIcon';
import useJobModalActions from '../../store/actions/jobModal';
import { jobToastLogsAtom, jobToastMessagesAtom } from '../../store/atoms/jobToasts';
import { apiLoadingFamily } from '../../store/atoms/api';
import { calculateTotalProgress, formatTimes } from './helpers';

export default function JobModal() {
  const t = useRecoilValue(translationsAtom);
  const logBackgroundProcessLoadingState = useRecoilValue(apiLoadingFamily('log-background-process'));
  const jobModalOpen = useRecoilValue(jobModalOpenAtom);
  const modalJob = useRecoilValue(modalJobAtom);
  const jobModalActions = useJobModalActions();
  const messages = useRecoilValue(jobToastMessagesAtom);
  const logs = useRecoilValue(jobToastLogsAtom);
  const [percentage, setPercentage] = useState(null);
  const [progress, setProgress] = useState(null);
  const [startedAt, setStartedAt] = useState(null);
  const [completedAt, setCompletedAt] = useState(null);
  const [processState, setProcessState] = useState(null);
  const [diff, setDiff] = useState(null);

  useEffect(() => {
    if (!modalJob || Object.keys(messages).length <= 0) {
      return;
    }

    jobModalActions.fetchLogs(messages[modalJob].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalJob]);

  useEffect(() => {
    if (!modalJob || Object.keys(messages).length <= 0) {
      return;
    }

    setProgress(messages[modalJob].progress);
    setStartedAt(messages[modalJob].startedAt);
    setCompletedAt(messages[modalJob].completedAt);
    setProcessState(messages[modalJob].processState);
    setDiff(messages[modalJob].diff);
  }, [modalJob, messages]);

  useEffect(() => {
    if (!modalJob || !progress || !startedAt || processState === 'error' || !diff) {
      setPercentage(null);
      return () => {};
    }

    if (completedAt) {
      setPercentage(100);
      return () => {};
    }

    let ticker;

    if (progress.type === 'percentage') {
      setPercentage(progress.percentage);
    }

    if (progress.type === 'predicted') {
      const times = formatTimes(progress.times || []);

      ticker = setInterval(() => {
        const elapsedMS = (Date.now() + diff) - (startedAt * 1000);

        setPercentage(calculateTotalProgress(elapsedMS, times, (startedAt * 1000)) * 100);
      }, (1000 / 20));
    }

    return () => {
      if (ticker) {
        clearInterval(ticker);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, startedAt, modalJob, completedAt, processState]);

  const fetchLogsCallback = useCallback(() => {
    if (!modalJob) {
      return;
    }

    jobModalActions.fetchLogs(messages[modalJob].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalJob, jobModalActions, messages]);

  const formattedLogs = useMemo(() => {
    if (logBackgroundProcessLoadingState === 'loading') {
      return (
        <span>loading...</span>
      );
    }

    return logs.map((log) => (
      <p key={log}>
        {log}
      </p>
    ));
  }, [logs, logBackgroundProcessLoadingState]);

  const modalContent = useMemo(() => {
    if (!modalJob || Object.keys(messages).length <= 0) {
      return '';
    }

    let icon = '';
    let iconClass = '';
    const {
      type,
      state,
      smallState,
      identifier,
    } = messages[modalJob];
    let smallStateOverwrite = null;
    let stateOverwrite = null;

    if (type === 'car') {
      icon = 'truck';
      iconClass = 'h-10 w-10 animate-drive';
    }

    if (type === 'upload') {
      icon = 'upload';
      iconClass = 'h-8 w-8 m-1 animate-bounce-slow';
    }

    if (type === 'done') {
      icon = 'checkRound';
      iconClass = 'h-6 w-6 m-2 text-green-500';
    }

    if (type === 'warn') {
      icon = 'exclamation';
      iconClass = 'h-8 w-8 m-1 text-orange-500';
    }

    if (type === 'error') {
      icon = 'exclamation';
      iconClass = 'h-8 w-8 m-1 text-red-500';
      smallStateOverwrite = 'Error';
      stateOverwrite = 'Error';
    }

    return (
      <>
        <Dialog.Title className="text-xl md:text-2xl font-bold flex flex-nowrap items-center">
          <SvgIcon identifier={icon} className={iconClass} />

          <span className="ml-3">
            {identifier}
          </span>
        </Dialog.Title>

        <Dialog.Description className="sr-only">
          Description
        </Dialog.Description>

        <div className="flex flex-col mt-3">
          <div className="border border-primary rounded-full w-full h-5">
            <div className="bg-primary h-full rounded-full" style={{ width: `${percentage}%` }} />
          </div>

          <div className="flex justify-between w-full">
            <span>
              {smallStateOverwrite || percentage ? `${Math.floor(percentage)}%` : smallState}
            </span>

            <span>
              {stateOverwrite || state}
            </span>
          </div>
        </div>

        <div className="flex flex-nowrap items-center mt-5">
          <span className="text-xl md:text-2xl font-bold mr-3">
            Log:
          </span>

          <button type="button" onClick={fetchLogsCallback}>
            <SvgIcon identifier="sync" className="h-5 w-5" />
          </button>
        </div>

        <div className="flex flex-col min-h-32 max-h-48 border border-primary rounded-md p-2 mt-3 overflow-x-scroll">
          {formattedLogs}
        </div>
      </>
    );
  }, [messages, modalJob, formattedLogs, fetchLogsCallback, percentage]);

  return (
    <Transition
      show={jobModalOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        as="div"
        onClose={() => jobModalActions.toggleModal(false)}
        className="fixed inset-0 z-30 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen p-4 md:p-10 overflow-hidden">
          <Dialog.Overlay
            className="fixed inset-0 bg-black opacity-30"
          />

          <div className="relative bg-white rounded-xl max-w-384 w-full mx-auto z-40">
            <div className="overflow-hidden w-full max-w-384 p-6 md:p-10 lg:p-14">
              {modalContent}
            </div>

            <button
              className="bg-primary hover:bg-primary-darker focus:bg-primary-darker transition-colors text-white p-3 md:p-4 absolute top-2 right-2 md:-top-4 md:-right-4 rounded-xl"
              type="button"
              onClick={() => jobModalActions.toggleModal(false)}
            >
              <span className="sr-only">{t.words.close}</span>
              <SvgIcon className="w-5 h-5" identifier="cross" />
            </button>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

JobModal.defaultProps = {
  //
};

JobModal.propTypes = {
  //
};
