const creepToOne = (elapsedMs) => {
  const rate = 0.00003;
  return 1 - Math.exp(-rate * elapsedMs);
};

const bezier = (t) => {
  const p0 = 0;
  const p1 = 0.5;
  const p2 = 0.2;
  const p3 = 0;
  const p4 = 0.9;
  const p5 = 0.9;

  return (1 - t) ** 5 * p0
    + 5 * (1 - t) ** 4 * t * p1
    + 10 * (1 - t) ** 3 * t ** 2 * p2
    + 10 * (1 - t) ** 2 * t ** 3 * p3
    + 5 * (1 - t) * t ** 4 * p4
    + t ** 5 * p5;
};

export const formatTimes = (timesArray) => {
  const total = timesArray.reduce((acc, item) => acc + item.time, 0);

  return timesArray.map((item) => ({
    ...item,
    time: item.time,
    percentage: item.time / total,
  }));
};

const calculateProgress = (ms, timeObject) => {
  const { time } = timeObject;

  if (ms >= time) {
    return 0.9 + (creepToOne(ms - time) / 10);
  }

  return bezier(ms / time);
};

export const calculateTotalProgress = (elapsedMS, timesArray, startTime) => {
  let completedProgress = 0;

  timesArray.forEach((timeObject) => {
    const { started, completed, percentage } = timeObject;

    if (started && completed) {
      completedProgress += percentage;
      return;
    }

    if (started) {
      const msDiff = elapsedMS - (started - startTime);

      if (msDiff <= 0) {
        return;
      }

      completedProgress += calculateProgress(msDiff, timeObject) * percentage;
    }
  });

  return completedProgress;
};
