import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../components/SvgIcon';
import formatSecondsToMMSS from '../../helpers/formatTime';
import { calculateTotalProgress, formatTimes } from './helpers';

export default function JobMessage({
  message,
  jobId,
  toggleCallback,
  openJobModalCallback,
  removeCallback,
  index,
}) {
  const [time, setTime] = useState(null);
  const {
    id,
    isVisible,
    isToggled,
    progress,
    type,
    state,
    smallState,
    identifier,
    processState,
    startedAt,
    completedAt,
    diff,
  } = message;
  const [percentage, setPercentage] = useState(null);

  useEffect(() => {
    if (!progress || processState === 'error') {
      setPercentage(null);
      return () => {};
    }

    if (completedAt) {
      setPercentage(100);
      return () => {};
    }

    let ticker;

    if (progress.type === 'percentage') {
      setPercentage(progress.percentage);
    }

    if (progress.type === 'predicted') {
      const times = formatTimes(progress.times || []);

      ticker = setInterval(() => {
        const elapsedMS = (Date.now() + diff) - (startedAt * 1000);

        setPercentage(calculateTotalProgress(elapsedMS, times, (startedAt * 1000)) * 100);
      }, (1000 / 20));
    }

    return () => {
      if (ticker) {
        clearInterval(ticker);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, startedAt, completedAt, processState]);

  useEffect(() => {
    if (processState === 'error' || processState === 'completed' || completedAt) {
      setTime(null);
      return () => {};
    }

    const clientTime = Math.floor((Date.now() + diff) / 1000);
    const differenceInSeconds = clientTime - startedAt;
    setTime(differenceInSeconds);

    const interval = setInterval(() => {
      setTime((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTime, startedAt, processState, completedAt]);

  let translateState = 'translate-x-full';
  let icon = '';
  let iconClass = '';
  let backgroundColor = 'bg-green-500';
  let smallStateOverwrite = null;
  let stateOverwrite = null;
  const offset = index * 71;

  if (isVisible) {
    translateState = 'translate-x-0';
  }

  if (isToggled && isVisible) {
    translateState = 'translate-x-[calc(100%-3.3rem)]';
  }

  if (type === 'car') {
    icon = 'truck';
    iconClass = 'h-10 w-10 animate-drive';
  }

  if (type === 'upload') {
    icon = 'upload';
    iconClass = 'h-8 w-8 m-1 animate-bounce-slow';
  }

  if (type === 'done') {
    icon = 'checkRound';
    iconClass = 'h-6 w-6 m-2';
  }

  if (type === 'error' || type === 'warn') {
    icon = 'exclamation';
    iconClass = 'h-8 w-8 m-1';
  }

  if (processState === 'warning') {
    backgroundColor = 'bg-orange-500';
  }

  if (processState === 'error') {
    backgroundColor = 'bg-red-500';
    smallStateOverwrite = 'Error';
    stateOverwrite = 'Error';
  }

  return (
    <div
      className={`absolute right-0 flex flex-col transition-transform ease-out duration-300 p-2 rounded-l-lg shadow-2xl text-white min-w-64 ${backgroundColor} ${translateState}`}
      style={{
        top: `${offset}px`,
      }}
    >
      <div className={`flex justify-between mb-0.5 ${isToggled ? 'max-w-[2.3rem]' : ''}`}>
        <button type="button" onClick={() => toggleCallback(jobId)}>
          <SvgIcon identifier={isToggled ? 'chevronLeft' : 'chevronRight'} className="h-3 w-3" />
        </button>

        {isToggled === false && (
          <>
            {time ? (
              <span className="text-sm ml-2 font-mono">
                {formatSecondsToMMSS(time)}
              </span>
            ) : ''}

            <span className="text-sm font-semibold mx-2 whitespace-nowrap">
              {identifier}
            </span>
          </>
        )}

        <div className="flex flex-nowrap">
          {isToggled === false && (
            <button
              type="button"
              onClick={() => {
                openJobModalCallback(jobId);
              }}
            >
              <SvgIcon identifier="info" className="h-[1.125rem] w-[1.125rem] mr-3" />
            </button>
          )}

          <button type="button" onClick={() => removeCallback(jobId, id)}>
            <SvgIcon identifier="cross" className="h-3 w-3" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-[auto_1fr]">
        <div className="flex items-center pr-2">
          {icon && (
            <SvgIcon identifier={icon} className={iconClass} />
          )}
        </div>

        <div className="flex items-center">
          <span className="text-sm">
            {stateOverwrite || state || smallStateOverwrite || smallState}
          </span>

          {percentage !== null && (
            <div className="border border-white rounded-full w-full h-2 ml-2">
              <div className="bg-white h-full" style={{ width: `${percentage}%` }} />
            </div>
          )}
        </div>

        <div className="flex items-center justify-center pr-2">
          {isToggled === true && (
            <span className="text-xs">
              {smallStateOverwrite || percentage ? `${Math.floor(percentage)}%` : smallState}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

JobMessage.defaultProps = {
  message: PropTypes.shape({
    progress: null,
    state: null,
    smallState: null,
    identifier: null,
    completedAt: null,
  }),
};

JobMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isVisible: PropTypes.bool.isRequired,
    isToggled: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    progress: PropTypes.object,
    type: PropTypes.string.isRequired,
    state: PropTypes.string,
    smallState: PropTypes.string,
    identifier: PropTypes.string,
    processState: PropTypes.string.isRequired,
    startedAt: PropTypes.number.isRequired,
    completedAt: PropTypes.number,
    diff: PropTypes.number,
  }),
  jobId: PropTypes.string.isRequired,
  toggleCallback: PropTypes.func.isRequired,
  openJobModalCallback: PropTypes.func.isRequired,
  removeCallback: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};
