import extractAddressComponents from './extractAddressComponents';

export default function autocomplePlaceToObject(place, validateHouseNumber = true) {
  const street = extractAddressComponents(place.address_components, 'route');
  const houseNumber = extractAddressComponents(place.address_components, 'street_number');
  const postalCode = extractAddressComponents(place.address_components, 'postal_code');

  if (!street) {
    throw new Error('Je hebt geen specifiek adres geselecteerd, zoek op een adres inclusief straat en huisnummer');
  }

  if (!houseNumber && validateHouseNumber) {
    throw new Error('Je hebt geen specifiek adres geselecteerd, zoek op een adres inclusief huisnummer');
  }

  if (!postalCode) {
    throw new Error('Je hebt adres geselecteerd zonder postcode, zoek op een adres inclusief postcode');
  }

  return {
    place_id: place.place_id,
    street,
    house_number: houseNumber || 'not_given',
    postal_code: postalCode,
    city: extractAddressComponents(place.address_components, 'locality')
      || extractAddressComponents(place.address_components, 'postal_town'),
    country: extractAddressComponents(place.address_components, 'country', true),
    country_long: extractAddressComponents(place.address_components, 'country'),
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    full_address_line: place.formatted_address,
  };
}
