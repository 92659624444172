export default (components, type, short = false) => {
  if (!components) {
    return '';
  }

  for (let i = 0; i < components.length; i += 1) {
    for (let j = 0; j < components[i].types.length; j += 1) {
      if (components[i].types[j] === type) {
        return components[i][short ? 'short_name' : 'long_name'];
      }
    }
  }

  return '';
};
