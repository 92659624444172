import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import analytics from '../../helpers/analytics';
import autocomplePlaceToObject from '../../helpers/autocomplePlaceToObject';
import { loadingStates } from '../../hooks/useApi';
import { apiErrorsFamily, apiLoadingFamily } from '../atoms/api';
import { translationsAtom } from '../atoms/i18n';
import { addressesAtom, inputValueAtom, tabAtom } from '../atoms/registerStepTasks';
import useRegisterActions from './register';
import useScheduleDetailActions from './scheduleDetail';

export default function useRegisterStepTasksActions() {
  const registerActions = useRegisterActions();
  const scheduleActions = useScheduleDetailActions();
  const navigate = useNavigate();

  const t = useRecoilValue(translationsAtom);
  const [addresses, setAddresses] = useRecoilState(addressesAtom);
  const [inputValue, setInputValue] = useRecoilState(inputValueAtom);
  const setTab = useSetRecoilState(tabAtom);
  const setLoadingState = useSetRecoilState(apiLoadingFamily('register-step-tasks'));
  const setErrors = useSetRecoilState(apiErrorsFamily('register-step-tasks'));

  const onNextStep = useCallback(async () => {
    const tasks = addresses.map((task) => ({ address: task }));

    setLoadingState(loadingStates.LOADING);

    // Store the tasks
    try {
      if (tasks.length) {
        await registerActions.enrichTasks({ tasks });
      }
    } catch (err) {
      setErrors([t.register.steps.tasks.error_schedule_create]);
      setLoadingState(loadingStates.ERROR);
      return;
    }

    // Create a schedule
    try {
      const res = await registerActions.createSchedule();

      if (typeof res === 'undefined' || typeof res.payload === 'undefined' || !res.payload.uuid) {
        throw new Error('Missing schedule response');
      }

      const scheduleUuid = res.payload.uuid;

      setLoadingState(loadingStates.IDLE);

      // Call schedule calculation
      scheduleActions.route(scheduleUuid, 'optimal');

      // Redirect to the created schedule
      navigate(`/schedules/${scheduleUuid}?subscription=pro-plus&ref=register`);
    } catch (err) {
      setErrors([t.register.steps.tasks.error_schedule_create]);
      setLoadingState(loadingStates.ERROR);
    }
  }, [
    registerActions,
    navigate,
    t,
    setErrors,
    setLoadingState,
    scheduleActions,
    addresses,
  ]);

  const onAddRandomAddresses = useCallback((limit) => {
    analytics.track('Add random addresses', {
      category: 'Registration flow',
    });

    registerActions.fetchRandomAddresses(limit)
      .then((data) => {
        const newAddresses = [...addresses, ...data];
        setAddresses(newAddresses);
      });
  }, [registerActions, addresses, setAddresses]);

  const onDeleteAddress = useCallback((indexToRemove) => {
    analytics.track('Delete address', {
      category: 'Registration flow',
    });

    const newAddresses = [...addresses]
      .filter((task, taskIndex) => taskIndex !== indexToRemove);
    setAddresses(newAddresses);
  }, [addresses, setAddresses]);

  const onPlaceChange = useCallback((event) => {
    setInputValue(event.target.value);
  }, [setInputValue]);

  const onPlaceSelect = useCallback((place) => {
    setErrors([]);

    try {
      const placeObj = autocomplePlaceToObject(place, true);

      // Prepend the new location on the tasks array.
      setAddresses((currentAddresses) => {
        return [placeObj, ...currentAddresses];
      });

      analytics.track('Add address', {
        category: 'Registration flow',
        label: inputValue,
      });

      // Reset location input
      setInputValue('');
    } catch (err) {
      setErrors([err.message]);
    }
  }, [
    setAddresses,
    inputValue,
    setInputValue,
    setErrors,
  ]);

  const onChangeTab = useCallback((tab) => {
    setTab(tab);
    setAddresses([]);
  }, [setTab, setAddresses]);

  return {
    onNextStep,
    onAddRandomAddresses,
    onDeleteAddress,
    onPlaceChange,
    onPlaceSelect,
    onChangeTab,
  };
}
