import { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import axios from 'axios';

export default function useEcho() {
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (!window.Pusher) {
      window.Pusher = Pusher;
    }

    if (window.Echo) {
      return;
    }

    window.Echo = new Echo({
      broadcaster: 'reverb',
      key: process.env.REACT_APP_WS_KEY,
      wsHost: process.env.REACT_APP_WS_HOST,
      wsPort: process.env.REACT_APP_WS_PORT,
      wssPort: process.env.REACT_APP_WSS_PORT,
      forceTLS: process.env.NODE_ENV !== 'development',
      enabledTransports: ['ws', 'wss'],
      authorizer: (channel) => {
        return {
          authorize: async (socketId, callback) => {
            return axios.post(`${process.env.REACT_APP_BROADCASTING_URL}/broadcasting/auth`, {
              socket_id: socketId,
              channel_name: channel.name,
            })
              .then((data) => {
                callback(false, data.data);
              })
              .catch((error) => {
                callback(true, error);
              });
          },
        };
      },
      reconnect: {
        auto: true,
        delay: 5000,
      },
    });

    window.Echo.connector.pusher.connection.bind('connected', () => {
      setIsConnected(true);
    });

    window.Echo.connector.pusher.connection.bind('failed', () => {
      setIsConnected(false);
    });

    window.Echo.connector.pusher.connection.bind('disconnected', () => {
      setIsConnected(false);
    });

    window.Echo.connector.pusher.connection.bind('unavailable', () => {
      setIsConnected(false);
    });
  }, []);

  return {
    isConnected,
  };
}
