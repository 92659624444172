/**
 * In various places parseISO causes issues with timezones.
 * When for example a date is returned as 2022-03-31 23:00, in London
 * timezone it will be kept at 2022-03-31. In Amsterdam timezone it becomes
 * 2022-04-01 00:00.
 *
 * As is the correct behaviour. But when we store the date yyyy-MM-dd formatted,
 * it will be different in London and Amsterdam.
 *
 * This method will remove the timezone offset.
 *
 * @param {Date|null} date
 * @returns {Date|null}
 */
export default function fixTimezoneOffset(date) {
  if (!date) {
    return null;
  }

  // Dates from the server are returned with a two hour offset.
  // We add those two hours back to the date, to get UTC+0.
  const serverTimezoneOffsetHours = 2;

  const updatedDate = new Date(date.getTime());

  updatedDate.setHours(updatedDate.getHours() + serverTimezoneOffsetHours);

  return updatedDate;
}
