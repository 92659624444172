import { atom } from 'recoil';

// eslint-disable-next-line import/prefer-default-export
export const jobToastMessagesAtom = atom({
  key: 'jobToastMessagesAtom',
  default: {},
});

export const jobToastLogsAtom = atom({
  key: 'jobToastLogsAtom',
  default: [],
});
