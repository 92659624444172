import { atom } from 'recoil';

export const addressesAtom = atom({
  key: 'registerStepTasksAddresses',
  default: [],
});

export const inputValueAtom = atom({
  key: 'registerStepTasksInputValue',
  default: '',
});

export const tabAtom = atom({
  key: 'registerStepTasksTab',
  default: 'manual',
});

export const importSuccessAtom = atom({
  key: 'importSuccess',
  default: false,
});
