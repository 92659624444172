import { atom } from 'recoil';

// eslint-disable-next-line import/prefer-default-export
export const jobModalOpenAtom = atom({
  key: 'jobModalOpenAtom',
  default: false,
});

export const modalJobAtom = atom({
  key: 'modalJobAtom',
  default: null,
});
