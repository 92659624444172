import { useRecoilState, useRecoilValue } from 'recoil';
import { subscriptionModalOpenAtom, subscriptionTrialModalOpenAtom } from '../atoms/subscriptionModal';
import { activeSubscriptionSelector, companySelector } from '../selectors/auth';

export default function useSubscriptionModalActions() {
  const [
    subscriptionModalOpen,
    setSubscriptionModalOpen,
  ] = useRecoilState(subscriptionModalOpenAtom);
  const [
    subscriptionTrialModalOpen,
    setSubscriptionTrialModalOpen,
  ] = useRecoilState(subscriptionTrialModalOpenAtom);
  const activeSubscription = useRecoilValue(activeSubscriptionSelector);
  const company = useRecoilValue(companySelector);

  function toggleModal(forceState = null) {
    if (!activeSubscription && company) {
      setSubscriptionModalOpen(true);
      return;
    }

    setSubscriptionModalOpen(forceState !== null ? forceState : !subscriptionModalOpen);
  }

  function toggleTrialModal(forceState = null) {
    setSubscriptionTrialModalOpen(forceState !== null ? forceState : !subscriptionTrialModalOpen);
  }

  return {
    toggleModal,
    toggleTrialModal,
  };
}
