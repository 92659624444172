import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { translationsLoadingStateAtom } from './store/atoms/i18n';
import Toasts from './components/Toasts';
import useI18nActions from './store/actions/i18n';
import setupYupLocale from './config/setupYupLocale';
import Routes from './Routes';
import issueCsrf from './services/issueCsrf';
import analytics from './helpers/analytics';
import useAuthActions from './store/actions/auth';
import SubscriptionModal from './containers/SubscriptionModal';
import TopUpModal from './containers/TopUpModal';
import FreemiumModal from './containers/FreemiumModal';
import SubscriptionTrialModal from './containers/SubscriptionTrialModal';
import TrialUpgradeModal from './components/TrialUpgradeModal';
import { userAtom } from './store/atoms/auth';
import useUpdateActions from './store/actions/updates';
import Intercom from './components/Intercom';
import JoyrideTooltip from './components/Joyride/JoyrideTooltip';
import JobToasts from './containers/JobToasts';

function App() {
  const translationsLoadingState = useRecoilValue(translationsLoadingStateAtom);
  const i18nActions = useI18nActions();
  const location = useLocation();
  const authActions = useAuthActions();
  const updateActions = useUpdateActions();
  const user = useRecoilValue(userAtom);

  useEffect(() => {
    i18nActions.fetch(user?.preferred_locale).then((translations) => {
      setupYupLocale(translations);
    });

    Sentry.setUser(
      user
        ? {
          id: user.id,
          email: user.email,
          company_id: user.company?.id,
          company_name: user.company?.name,
          active_subscription: user.company?.active_subscription?.subscription_plan?.identifier ?? 'unknown',
          preferred_locale: user.preferred_locale,
        }
        : null,
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    issueCsrf();
    authActions.fetchUser();
    updateActions.fetchUpdates();

    Sentry.setContext('window dimensions', {
      width: window.innerWidth,
      height: window.innerHeight,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const html = document.documentElement;

    if (html.style.overflow === 'hidden') {
      html.style.overflow = '';
      html.style.paddingRight = '';
    }

    analytics.page();

    // Bit hacky, but the LinkedIn Pixel does not natively support
    // SPA pageviews. By triggering track, a view is send to LinkedIn.
    if (typeof window.lintrk !== 'undefined') {
      window.lintrk('track');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (translationsLoadingState === 'loading') {
    return (
      <div className="bg-primary-background min-h-screen md:flex">
        Een moment geduld...
      </div>
    );
  }

  return (
    <>
      <JoyrideTooltip />
      <JobToasts />

      <div className="bg-primary-background min-h-screen md:flex">
        <SubscriptionModal />
        <Routes />
        <Toasts />
        <SubscriptionTrialModal />
        <TopUpModal />
        <FreemiumModal />
        <TrialUpgradeModal />
        <Intercom />
      </div>
    </>
  );
}

export default App;
