import { atom } from 'recoil';

// eslint-disable-next-line import/prefer-default-export
export const registerModalOpenAtom = atom({
  key: 'registerModalOpenAtom',
  default: false,
});

export const registerModalRouteDoneAtom = atom({
  key: 'registerModalRouteDoneAtom',
  default: false,
});
