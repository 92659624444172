import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { useRecoilValue } from 'recoil';
import { subscriptionModalOpenAtom } from '../../store/atoms/subscriptionModal';
import { translationsAtom } from '../../store/atoms/i18n';
import useSubscriptionModalActions from '../../store/actions/subscriptionModal';
import Spinner from '../../components/Spinner';
import { apiLoadingFamily } from '../../store/atoms/api';
import SvgIcon from '../../components/SvgIcon';
import Plans from '../../components/Subscription/Plans';
import UnitToggle from '../../components/UnitToggle';
import analytics from '../../helpers/analytics';
import { subscriptionsAtom } from '../../store/atoms/subscription';
import useSubscriptionActions from '../../store/actions/subscription';
import {
  activeSubscriptionSelector,
  companySelector,
  subscriptionPlanSelector,
} from '../../store/selectors/auth';

export default function SubscriptionModal() {
  const t = useRecoilValue(translationsAtom);
  const [showPricesUnit, setShowPricesUnit] = useState('yearly');
  const subscriptionModalOpen = useRecoilValue(subscriptionModalOpenAtom);
  const subscriptions = useRecoilValue(subscriptionsAtom);
  const subscriptionsLoadingState = useRecoilValue(apiLoadingFamily('subscriptions'));
  const subscriptionModalActions = useSubscriptionModalActions();
  const subscriptionActions = useSubscriptionActions();

  const activeSubscriptionPlan = useRecoilValue(subscriptionPlanSelector);
  const activeSubscription = useRecoilValue(activeSubscriptionSelector);
  const company = useRecoilValue(companySelector);

  useEffect(() => {
    if (!activeSubscription && company) {
      subscriptionModalActions.toggleModal(true);
    }

    if (activeSubscription || !company) {
      subscriptionModalActions.toggleModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubscriptionPlan, activeSubscription, company]);

  useEffect(() => {
    if (activeSubscription || company?.has_had_subscription) {
      setShowPricesUnit('yearly');
    }

    if (!activeSubscription && !company?.has_had_subscription) {
      setShowPricesUnit('monthly');
    }
  }, [activeSubscription, company?.has_had_subscription]);

  useEffect(() => {
    if (subscriptionModalOpen) {
      subscriptionActions.fetchSubscriptions();

      analytics.track('Subscription modal open', {
        category: 'Subscription modal open',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionModalOpen]);

  const subscriptionAlert = useMemo(() => {
    if (activeSubscription) {
      return '';
    }

    return (
      <div className="flex mb-16 p-5 bg-yellow-100 flex-col">
        <span className="font-bold mr-2">
          {
            company?.has_had_subscription
              ? t.subscription_modal.title_expired
              : t.subscription_modal.title_short_term
          }
        </span>
        <span className="whitespace-pre-wrap">
          {
            company?.has_had_subscription
              ? t.subscription_modal.description_expired
              : t.subscription_modal.description_short_term
          }
        </span>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubscription, company?.has_had_subscription]);

  const onShowPricesToggle = useCallback((checkState) => {
    setShowPricesUnit(checkState ? 'yearly' : 'monthly');
  }, []);

  let contents = (
    <>
      {subscriptionAlert && (
        <div className="mt-6">
          {subscriptionAlert}
        </div>
      )}

      <div className="flex items-center justify-center py-5 md:py-20 lg:py-40">
        <Spinner />
      </div>
    </>
  );

  if (subscriptionsLoadingState !== 'loading' && subscriptions && subscriptions.plans) {
    contents = (
      <div className="pt-6 lg:pt-0 lg:-mt-6">
        <div className={`flex justify-center ${subscriptionAlert ? 'mb-8' : 'mb-16'}`}>
          <UnitToggle
            enabled={showPricesUnit === 'yearly'}
            onChange={onShowPricesToggle}
            leftText={t.subscription_modal.monthly_billing}
            rightText={t.subscription_modal.yearly_billing}
            description={t.subscription_modal.billing_switch_description}
          />
        </div>

        {subscriptionAlert}

        <Plans
          plans={subscriptions.plans}
          features={subscriptions.compact_features}
          extras={subscriptions.extras}
          showPricesUnit={showPricesUnit}
        />
      </div>
    );
  }

  return (
    <Transition
      show={subscriptionModalOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        as="div"
        onClose={() => subscriptionModalActions.toggleModal(false)}
        className="fixed inset-0 z-30 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen p-4 md:p-10 overflow-hidden">
          <Dialog.Overlay
            className="fixed inset-0 bg-black opacity-30"
          />

          <div className="relative bg-white rounded-xl max-w-384 w-full mx-auto z-40">
            <div className="overflow-hidden w-full max-w-384 p-6 md:p-10 lg:p-14">
              <Dialog.Title className="text-xl md:text-2xl font-bold">
                {t.subscription_modal.title}
              </Dialog.Title>

              <Dialog.Description className="sr-only">
                {t.subscription_modal.description}
              </Dialog.Description>

              {contents}
            </div>

            {!subscriptionAlert && (
              <button
                className="bg-primary hover:bg-primary-darker focus:bg-primary-darker transition-colors text-white p-3 md:p-4 absolute top-2 right-2 md:-top-4 md:-right-4 rounded-xl"
                type="button"
                onClick={() => subscriptionModalActions.toggleModal(false)}
              >
                <span className="sr-only">{t.words.close}</span>
                <SvgIcon className="w-5 h-5" identifier="cross" />
              </button>
            )}

            {subscriptionAlert && (
              <div className="flex justify-center mb-10 -mt-10">
                <NavLink
                  to="/logout"
                  className="bg-primary btn px-10 text-white"
                >
                  {t.navigation.logout}
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

SubscriptionModal.defaultProps = {
  //
};

SubscriptionModal.propTypes = {
  //
};
