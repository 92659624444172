import { useSetRecoilState } from 'recoil';
import useApi from '../../hooks/useApi';
import { apiLoadingFamily } from '../atoms/api';
import useToastActions from './toasts';

export default function useBackgroundProcessActions() {
  const toastActions = useToastActions();
  const muteBackgroundProcessLoadingState = useSetRecoilState(apiLoadingFamily('mute-background-process'));
  const fetchBackgroundProcessLoadingState = useSetRecoilState(apiLoadingFamily('fetch-background-process'));

  const { handle: backgroundProcessMuteApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => muteBackgroundProcessLoadingState(loadingState),
  });

  const { handle: backgroundProcessFetchApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => fetchBackgroundProcessLoadingState(loadingState),
  });

  async function mute(data) {
    const result = await backgroundProcessMuteApiHandle('post', 'mute-background-process', data);

    return result;
  }

  async function fetch() {
    const result = await backgroundProcessFetchApiHandle('get', 'fetch-background-process');

    return result;
  }

  return {
    mute,
    fetch,
  };
}
