import { selector } from 'recoil';
import Fuse from 'fuse.js';
import { compareAsc, parseISO, startOfDay } from 'date-fns';
import {
  scheduleTasksAtom,
  scheduleTasksSearchAtom,
  scheduleVehiclesAtom,
  scheduleVehiclesSearchAtom,
  scheduleSelectedTasksAtom,
  scheduleSelectedVehiclesAtom,
  schedulesDetailItemAtom,
} from '../atoms/scheduleDetail';
import { apiLoadingFamily } from '../atoms/api';
import { loadingStates } from '../../hooks/useApi';
import fixTimezoneOffset from '../../helpers/fixTimezoneOffset';

export const scheduleDetailTasksListSelector = selector({
  key: 'scheduleDetailTasksListSelector',
  get: ({ get }) => {
    const searchQuery = get(scheduleTasksSearchAtom);
    const tasks = get(scheduleTasksAtom);

    if (!searchQuery || searchQuery.length < 3) {
      return tasks;
    }

    const fuse = new Fuse(tasks, {
      minMatchCharLength: 2,
      includeScore: true,
      // 0 will only find exact matches, 1 will find everything.
      threshold: 0.4,
      keys: [
        'external_identifier',
        'track_trace_token',
        'address.street',
        'address.house_number',
        'address.postal_code',
        'address.city',
        'address.country.description',
        'customer.name',
        'customer.email',
        'customer.phone_number',
      ],
    });

    return fuse.search(searchQuery).map((fuseResult) => fuseResult.item);
  },
});

export const scheduleDetailVehiclesListSelector = selector({
  key: 'scheduleDetailVehiclesListSelector',
  get: ({ get }) => {
    const searchQuery = get(scheduleVehiclesSearchAtom);
    const vehicles = get(scheduleVehiclesAtom);

    if (!searchQuery) {
      return vehicles;
    }

    const fuse = new Fuse(vehicles, {
      minMatchCharLength: 2,
      includeScore: true,
      // 0 will only find exact matches, 1 will find everything.
      threshold: 0.4,
      keys: [
        'license_plate',
        'description',
      ],
    });

    return fuse.search(searchQuery).map((fuseResult) => fuseResult.item);
  },
});

export const scheduleDetailAllVehiclesSelected = selector({
  key: 'scheduleDetailAllVehiclesSelected',
  get: ({ get }) => {
    const scheduleDetailItem = get(schedulesDetailItemAtom);
    const selectedVehicles = get(scheduleSelectedVehiclesAtom);
    const vehicles = get(scheduleVehiclesAtom);

    if (vehicles.length === 0) {
      return false;
    }

    if (scheduleDetailItem.daily_schedule_vehicles_count >= scheduleDetailItem.max_vehicles) {
      return true;
    }

    return vehicles.length === selectedVehicles.length;
  },
});

export const scheduleDetailAllTasksSelected = selector({
  key: 'scheduleDetailAllTasksSelected',
  get: ({ get }) => {
    const selectedTasks = get(scheduleSelectedTasksAtom);
    const tasks = get(scheduleTasksAtom);

    if (tasks.length === 0) {
      return false;
    }

    return tasks.length === selectedTasks.length;
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

export const scheduleDetailIsLoadingSelector = selector({
  key: 'scheduleDetailIsLoadingSelector',
  get: ({ get }) => {
    const scheduleDetailItemLoadingState = get(apiLoadingFamily('schedule-detail'));
    const scheduleSortLoadingState = get(apiLoadingFamily('schedule-sort'));
    const scheduleRouteLoadingState = get(apiLoadingFamily('schedule-route'));

    return (
      scheduleDetailItemLoadingState === loadingStates.LOADING
      || scheduleSortLoadingState === loadingStates.LOADING
      || scheduleRouteLoadingState === loadingStates.LOADING
    );
  },
});

export const scheduleDetailIsInPastSelector = selector({
  key: 'scheduleDetailIsInPastSelector',
  get: ({ get }) => {
    const scheduleDetailItem = get(schedulesDetailItemAtom);

    if (!scheduleDetailItem) {
      return false;
    }

    const scheduleDate = startOfDay(fixTimezoneOffset(parseISO(scheduleDetailItem.date)));
    const nowDate = startOfDay(new Date());

    // Comparison will return -1 when the scheduleDate is before nowDate.
    // 0 if it is the same and 1 when the scheduleDate is in the future.
    // Source: https://date-fns.org/v2.28.0/docs/compareAsc
    const compareResult = compareAsc(scheduleDate, nowDate);

    return compareResult === -1;
  },
});

export const scheduleDetailOutstandingUpdatesSelector = selector({
  key: 'scheduleDetailOutstandingUpdatesSelector',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
  get: ({ get }) => {
    const scheduleDetailItem = get(schedulesDetailItemAtom);

    return (
      scheduleDetailItem?.calculated_at
      && !scheduleDetailItem?.submitted_at
    );
  },
});

export const scheduleDetailIsCalculatedSelector = selector({
  key: 'scheduleDetailIsCalculatedSelector',
  get: ({ get }) => {
    const scheduleDetailItem = get(schedulesDetailItemAtom);

    return !!scheduleDetailItem?.calculated_at;
  },
});

export const scheduleDetailOptimalisationTypeSelector = selector({
  key: 'scheduleDetailOptimalisationTypeSelector',
  get: ({ get }) => {
    const scheduleDetailItem = get(schedulesDetailItemAtom);

    return (
      scheduleDetailItem
      && scheduleDetailItem.optimalisation_type
    );
  },
});
