import { useRecoilState, useSetRecoilState } from 'recoil';
import { jobModalOpenAtom, modalJobAtom } from '../atoms/jobModal';
import { apiLoadingFamily } from '../atoms/api';
import useApi from '../../hooks/useApi';
import useToastActions from './toasts';
import { jobToastLogsAtom } from '../atoms/jobToasts';

export default function useJobModalActions() {
  const setLogs = useSetRecoilState(jobToastLogsAtom);
  const toastActions = useToastActions();
  const logBackgroundProcessLoadingState = useSetRecoilState(apiLoadingFamily('log-background-process'));
  const [
    jobModalOpen,
    setJobModalOpen,
  ] = useRecoilState(jobModalOpenAtom);
  const setModalJob = useSetRecoilState(modalJobAtom);

  const { handle: backgroundProcessLogApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => logBackgroundProcessLoadingState(loadingState),
  });

  function toggleModal(jobId, forceState = null) {
    setModalJob(jobId);
    setJobModalOpen(forceState !== null ? forceState : !jobModalOpen);
  }

  async function fetchLogs(id) {
    const result = await backgroundProcessLogApiHandle('get', `log-background-process?id=${id}`, {});

    if (result) {
      setLogs(result);
    }

    return result;
  }

  return {
    toggleModal,
    fetchLogs,
  };
}
