import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import weakPasswords from '../config/weakPasswords';
import { translationsAtom } from '../store/atoms/i18n';

export default function useRegisterSchema() {
  const t = useRecoilValue(translationsAtom);

  const schema = {
    type: yup
      .string()
      .required()
      .oneOf(['business', 'private']),
    term: yup
      .string()
      .required()
      .oneOf(['short', 'long']),
    company_name: yup
      .string()
      .required()
      .min(2)
      .max(200),
    name: yup
      .string()
      .required()
      .min(2)
      .max(200),
    phone_number: yup
      .string()
      .required()
      .min(6)
      .max(20),
    email: yup
      .string()
      .required()
      .email()
      .min(2)
      .max(200),
    password: yup
      .string()
      .required()
      .min(6)
      .max(200)
      .test('passwords-weak', t.validation.custom.password_weak, (value) => {
        return weakPasswords.indexOf(value) < 0;
      }),
    acquisition_explanation: yup
      .string()
      .required()
      .max(5000),
  };

  const cyclicDependencies = [
    //
  ];

  const registerVehiclesSchema = {
    vehicles_count: yup
      .number()
      .transform((curr, orig) => {
        return orig === '' ? 0 : curr;
      })
      .required()
      .min(1)
      .max(2000)
      .label('aantal voertuigen'),
  };

  const registerVehiclesCyclicDependencies = [
    ['vehicles_count', 'vehicles_count'],
  ];

  return {
    schema,
    registerVehiclesSchema,
    cyclicDependencies,
    registerVehiclesCyclicDependencies,
  };
}
