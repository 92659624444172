import { atom } from 'recoil';

export const schedulesDetailItemAtom = atom({
  key: 'schedulesDetailItemAtom',
  default: null,
});

export const scheduleTasksAtom = atom({
  key: 'scheduleTasksAtom',
  default: [],
});

export const scheduleTasksSearchAtom = atom({
  key: 'scheduleTasksSearchAtom',
  default: '',
});

export const scheduleSelectedTasksAtom = atom({
  key: 'scheduleSelectedTasksAtom',
  default: [],
});

export const scheduleVehiclesAtom = atom({
  key: 'scheduleVehiclesAtom',
  default: [],
});

export const scheduleVehiclesSearchAtom = atom({
  key: 'scheduleVehiclesSearchAtom',
  default: '',
});

export const scheduleSelectedVehiclesAtom = atom({
  key: 'scheduleSelectedVehiclesAtom',
  default: [],
});

export const scheduleChangeWarningShownAtom = atom({
  key: 'scheduleChangeWarningShownAtom',
  default: false,
});

export const scheduleAlternativeAtom = atom({
  key: 'scheduleAlternativeAtom',
  default: null,
});

export const scheduleActiveTaskId = atom({
  key: 'scheduleActiveTaskIdAtom',
  default: null,
});

export const scheduleActiveVehicleId = atom({
  key: 'scheduleActiveVehicleIdAtom',
  default: null,
});

export const scheduleDetailNotificationModal = atom({
  key: 'scheduleDetailNotificationModal',
  default: false,
});

export const scheduleDetailIsCalculating = atom({
  key: 'scheduleDetailIsCalculating',
  default: false,
});
